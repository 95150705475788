import resources from './resources';

const lang = localStorage.getItem('language');
console.log('lang:', lang);

export const config = {
    interpolation: {
        escapeValue: false, // React already does escaping
    },
    lng: lang ? lang : 'nl',
    resources,
};

export { resources };
