import { Box, Button, Grid, Typography } from '@mui/material';
import ContractList from '@src/components/contractList/contractList';
import SearchForm from '@src/components/contractList/searchForm';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// import SearchForm from '@src/components/contractList/SearchForm';
// import ContractList from '@src/components/contractList/contractList';
// import { ComponentToPrint } from '@src/components/contractList/print';

const ListContracts = () => {
    const [pageSize, setPageSize] = useState<number>(10);
    const { t } = useTranslation('common');

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Grid
                    container
                    sx={{
                        backgroundColor: 'white',
                        boxShadow: '10px 10px 10px rgba(0,0,0,.1)',
                        width: '80%',
                        margin: '10px auto auto auto',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography variant="h4" sx={{ textAlign: 'center', margin: '1rem auto 0.2rem' }}>
                                {t('list_contracts.title')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ justifyContent: 'center' }}>
                        <Grid item xs={12}>
                            <SearchForm pageSize={pageSize} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    className="step__container"
                    container
                    sx={{
                        // borderRadius: '35px',
                        backgroundColor: 'white',
                        // border: { xs: 'none', md: '10px solid rgba(250,250,250,.5)' },
                        boxShadow: '10px 10px 10px rgba(0,0,0,.1)',
                        minHeight: '550px',
                        width: '80%',
                        padding: '2rem',
                        margin: '1rem auto',
                        display: 'flex',
                        position: 'relative',
                    }}
                >
                    <ContractList pageSize={pageSize} setPageSize={setPageSize} />
                </Grid>
            </Box>
        </>
    );
};

export default ListContracts;
