import dayjs from 'dayjs';
import axiosInstance from '../http/axiosSetup';
import axios, { AxiosRequestConfig } from 'axios';
import { setNotification } from '@src/components/ui/Notifications';

export type smsAuthType = {
    smsId: number;
    smsCode: string;
};
const localInstanceConfig: AxiosRequestConfig = {
    baseURL: 'http://127.0.0.1:9999/beID/read?all=true',
};
const localInstance = axios.create(localInstanceConfig);

export default class ContractService {
    // Contractlistázásra alkalmatlan amennyiben az első elem adatai korlátozottak a többihez képest. Emiatt létrejött a searchContractForList
    static searchContract = (params) =>
        axiosInstance
            .get('contracts', { params })
            .then((res) => {
                const contract = res.data._embedded.contracts[0];
                // ! Extend response with required keys for Object Classes
                // ! contract
                contract.country = contract.countryId;
                contract.language = contract.languageId;
                contract.postCode = contract.postcode;
                contract.houseNumber = contract.streetNumber;
                contract.boxNumber = contract.postbox;
                contract.vatNumber = contract.taxIdentificationNumber;
                // ! partner
                contract.partner = contract.partnerId;
                contract.shop = contract.shop.id;
                contract.shopName = contract.shop.name;
                contract.sellingDate = dayjs(new Date(contract.salesDate));
                contract.deliveryDate = dayjs(new Date(contract.shipDate));
                contract.employee = contract.employee.id;
                contract.employeeName = contract.employee.name;
                return res;
            })
            .catch((err) => {
                throw err;
            });
    static searchContractForList = (params) =>
        axiosInstance
            .get('contracts', { params })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw err;
            });
    static getProjectProperties = (queryParams) => {
        return axiosInstance
            .get('project/properties', {
                params: queryParams,
            })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static sendMail = (body) => {
        return axiosInstance
            .get(`/contracts/${body?.partnerId}/${body?.contractNumber}/registration-mail`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static saveModifiedContract = (body) => {
        return axiosInstance
            .patch('/contracts', body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static saveContract = (body) => {
        return axiosInstance
            .post('/contracts', body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static cancelContract = (body, partnerId, contractNumber) => {
        return axiosInstance
            .patch(`contracts/${partnerId}/${contractNumber}/cancel`, body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static getContract = (partnerId, contractNumber) => {
        return axiosInstance
            .get(`contracts/${partnerId}/${contractNumber}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static getAccount = (partnerId, contractNumber) => {
        return axiosInstance
            .get(`contracts/${partnerId}/${contractNumber}/account`)
            .then((res) => {
                const account = res.data;
                console.log('ACCOUNT RES', res.data);

                // ! Extend response with required keys for Object Classes
                account.customerNameAh = account?.customerName;
                account.countryAh = account?.countryId;
                account.languageAh = account?.languageId;
                account.postCodeAh = account?.postcode;
                account.cityAh = account?.city;
                account.streetAh = account?.street;
                account.houseNumberAh = account?.streetNumber;
                account.phoneNumberAh = account?.phoneNumber;
                account.emailAh = account?.email;

                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static getShop = (partnerId, shopId) => {
        return axiosInstance
            .get(`shop-managements/shops/${partnerId}/${shopId}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };

    // static getAccount = (partnerId, contractNumber) => {
    //     return axiosInstance
    //         .get(`contract/account/get/${contractNumber}/${partnerId}`)
    //         .then((res) => {
    //             const account = res.data.account;

    //             // ! Extend response with required keys for Object Classes
    //             account.customerNameAh = account?.customername;
    //             account.countryAh = account?.con_countryid;
    //             account.languageAh = account?.languageid;
    //             account.postCodeAh = account?.postcode;
    //             account.cityAh = account?.city;
    //             account.streetAh = account?.street;
    //             account.houseNumberAh = account?.street_nr;
    //             account.phoneNumberAh = account?.phonenb;
    //             account.emailAh = account?.email;

    //             return res;
    //         })
    //         .catch((err) => {
    //             return err.response;
    //         });
    // };
    static getDiaries = (partnerId, contractNumber) => {
        return axiosInstance
            .get(`contracts/${partnerId}/${contractNumber}/diaries`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static getTermsAndConditions = (partnerId, contractNumber) => {
        return axiosInstance
            .get(`contracts/${partnerId}/${contractNumber}/condition-link`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static getClaims = (partnerId, contractNumber) => {
        return axiosInstance
            .get(`claims?prt_id=${partnerId}&con_number=${contractNumber}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    // static getBankList = (body, queryParams) => {
    //     return axiosInstance
    //         .post(`bank/list`, body, queryParams)
    //         .then((res) => {
    //             return res;
    //         })
    //         .catch((err) => {
    //             return err.response;
    //         });
    // };
    static verifySmsCode = (body: smsAuthType) => {
        return axiosInstance
            .post('/sms-auth', body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static sendSmsCode = (body: Record<'phoneNumber', string>, partnerId: string, contractNumber: number) => {
        return axiosInstance
            .post(`contracts/${partnerId}/${contractNumber}/sms-sign`, body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static readCard = () => {
        return localInstance
            .get(`http://127.0.0.1:9999/beID/read?all=true`, { auth: { username: process.env.CARD_USER!, password: process.env.CARD_PW! } })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log(err.message);
                // if (err.message == 'Network Error') {
                //     setNotification('Error', `${err.message}, maybe beid-1.0.1.jar is not running on this system!`, 'danger');
                // } else {
                //     {
                //         setNotification('Error', `Error at card reading!`, 'danger');
                //     }
                // }

                return err;
            });
    };
    static emailCheck = (body: Record<string, any>) => {
        return axiosInstance
            .post(`validations/email-address-validation`, body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };

    // const readCard = <T>(): Observable<T | void> => {
    //     return defer(() => localInstance.get('http://localhost:9999/beID/read?all=true', { auth: { username: process.env.CARD_USER!, password: process.env.CARD_PW! } })).pipe(map((res: any) => res));
    // };
}
