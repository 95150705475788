import { Box, CardMedia, FormControl, Grid, Link, MenuItem, Modal, Select, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import PreviewIcon from '@mui/icons-material/Visibility';
import { ComponentToPrint } from './print';
import { Button } from '@mui/material';
// import ReactToPrint from 'react-to-print';
import { useHistory } from 'react-router-dom';
import {
    GET_DIARIES,
    //  GET_CONTRACT_DATA,
    GET_PROJECT_PROPERTIES,
    SEARCH_CONTRACT,
    SEARCH_CONTRACT_FOR_LIST,
    SET_CONTRACT_LIST,
    SET_CONTRACT_LIST_LINKS,
    SET_DIARIES,
} from '@src/redux/contract/actions';
import ContractPreview from './contractPreview';
import CancelContract from './cancelContract';
import RootState from '@src/interfaces/RootState';
import { SET_LOADING } from '@src/redux/ui/actions';
import { dateFormatterBe } from '../Utility/util';
import { cancelled } from 'redux-saga/effects';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { setNotification } from '../ui/Notifications';
import { downloadExcel } from 'react-export-table-to-excel';
import * as SelectInterfaces from '@src/interfaces/SelectInterface';
import { Field } from 'redux-form';
import renderSelectField from '../form/Select';
import * as writeXLSX from 'sheetjs-style';
import { saveAs } from 'file-saver';
import ContractService from '@src/api/services/contractService';
import { SET_ERROR_OBJECT } from '@src/redux/error/actions';

export const tableLengthList: SelectInterfaces.SelectList = [
    // { name: 'EN', value: 'en', label: 'EN' },
    { name: '10', value: '10', label: '10' },
    { name: '20', value: '20', label: '20' },
    { name: '30', value: '30', label: '30' },
    { name: '40', value: '40', label: '40' },
    { name: '100', value: '1000', label: '1000' },
];

const ContractList = ({ pageSize, setPageSize }) => {
    const [preview, setPreview] = useState<null | number>(null);
    const [contractList, setContractList] = useState<any>([]);
    const [actualPartnerId, setActualPartnerId] = useState<string | number | null>(null);
    const [actualContractNumber, setActualContractNumber] = useState<string | number | null>(null);
    const [insertDate, setInsertDate] = useState<string | Date | null>(null);
    // const [cancelView, setCancelView] = useState<null | number>(null);
    const componentRef = useRef();
    const dispatchStore = useDispatch();
    const history = useHistory();
    const { t } = useTranslation('common');
    const getProjectProperties = useSelector((state: RootState) => state?.contract?.projectProperties);
    const contractListFromContract = useSelector((state: RootState) => (state.contract as any)?.contractList);
    const contractListPageInfo = useSelector((state: RootState) => (state.contract as any)?.contractListPageInfo);
    const getContract = useSelector((state: RootState) => state.contract as any);
    // const { handleSubmit, reset, control, setValue, getValues } = useForm();

    const dateFormatter = (dateToFormat) => {
        const date = new Date(dateToFormat);

        const y = date.getFullYear();
        const m = ('0' + (date.getMonth() + 1)).slice(-2);
        const d = ('0' + date.getDate()).slice(-2);

        const formattedDate = `${d}/${m}/${y}`;
        return formattedDate;
    };

    const calculateDaysToEdit = (today, projectProperties) => {
        if (!projectProperties || projectProperties.days_ws_pre_reg === undefined) {
            return null;
        }

        const workdaysToAdd = projectProperties.days_to_modify;

        if (workdaysToAdd === 0) {
            return today; // Ha nincs munkanap hozzáadva, akkor a mai napot adjuk vissza
        }

        // Például, ha a hét hétfőtől péntekig tart
        const workdaysPerWeek = 5;
        const resultDate = new Date(today);
        resultDate.setHours(23);
        resultDate.setMinutes(59);
        resultDate.setSeconds(59);

        let workdaysRemaining = workdaysToAdd;

        while (workdaysRemaining > 0) {
            resultDate.setDate(resultDate.getDate() + 1); // Következő nap

            // Ellenőrizze, hogy a jelenlegi nap munkanap-e (hétfő-péntek)
            const dayOfWeek = resultDate.getDay();
            if (dayOfWeek >= 1 && dayOfWeek <= workdaysPerWeek) {
                workdaysRemaining--;
            }
        }

        return resultDate;
    };

    const getDiaries = (partnerId, contractNumber) => {
        dispatchStore({ type: GET_DIARIES, val: { partnerId: partnerId, contractNumber: contractNumber } });
        setActualPartnerId(partnerId);
        setActualContractNumber(contractNumber);
    };

    const editContract = (partnerId, contractNumber) => {
        if (Date.now() <= calculateDaysToEdit(insertDate, getProjectProperties).getTime()) {
            dispatchStore({ type: SET_LOADING, val: true });
            dispatchStore({ type: SET_CONTRACT_LIST, val: null });
            // getContract(contractNumber);
            setActualContractNumber(null);
            setActualPartnerId(null);
            setInsertDate(null);
            dispatchStore({ type: SET_DIARIES, val: null });
            dispatchStore({ type: SET_CONTRACT_LIST_LINKS, val: null });

            setTimeout(() => {
                history.push(`/edit-contract/${partnerId}/${contractNumber}`);
            }, 1000);
        } else {
            setNotification('Warning', `#${contractNumber} ${t('contract.contract_edit_project_properties')}`, 'warning');
        }
    };
    const viewContract = (partnerId, contractNumber) => {
        dispatchStore({ type: SET_LOADING, val: true });
        dispatchStore({ type: SET_CONTRACT_LIST, val: null });
        dispatchStore({ type: SET_CONTRACT_LIST_LINKS, val: null });

        // getContract(contractNumber);
        setTimeout(() => {
            history.push(`/view-contract/${partnerId}/${contractNumber}`);
        }, 1000);
    };

    const printPreview = (contractNumber) => {
        console.log('printPreview ~ contractNumber:', contractNumber);
        setPreview(contractNumber);
    };

    const pagination = (url) => {
        const formArray = new Array<Array<string | number>>();
        let key: string | number;
        let value: string | number;
        const formData = {
            page: url?.split('page=')[1]?.split('&')[0],
            // size: sessionStorage.getItem('pageSize') ? sessionStorage.getItem('pageSize') : 10,
            size: url?.includes('size') ? url?.split('size=')[1]?.split('&')[0] : null,
            contractNumber: url?.includes('contractNumber') ? url?.split('contractNumber=')[1]?.split('&')[0] : null,
            customerName: url?.includes('customerName') ? url?.split('customerName=')[1]?.split('&')[0] : null,
            serialNumber: url?.includes('serialNumber') ? url?.split('serialNumber=')[1]?.split('&')[0] : null,
            employeeName: url?.includes('employeeName') ? url?.split('employeeName=')[1]?.split('&')[0] : null,
            contractRegistrationDateFrom: url?.includes('contractRegistrationDateFrom') ? url?.split('contractRegistrationDateFrom=')[1]?.split('&')[0] : null,
            contractRegistrationDateTo: url?.includes('contractRegistrationDateTo') ? url?.split('contractRegistrationDateTo=')[1]?.split('&')[0] : null,
        };

        for ([key, value] of Object.entries(formData)) {
            if (value !== undefined && value !== null) {
                formArray.push([key, value]);
            }
        }

        const payLoad = Object.fromEntries(formArray);
        dispatchStore({ type: SEARCH_CONTRACT_FOR_LIST, val: payLoad });
    };

    function handleDownloadExcel() {
        const formArray = new Array<Array<string | number>>();
        let key: string | number;
        let value: string | number;
        const url = getContract?.contractListLinks?.first?.href ? getContract?.contractListLinks?.first?.href : getContract?.contractListLinks?.self?.href;
        const formData = {
            page: url?.split('page=')[1]?.split('&')[0],
            // size: sessionStorage.getItem('pageSize') ? sessionStorage.getItem('pageSize') : 10,
            size: 999,
            contractNumber: url?.includes('contractNumber') ? url?.split('contractNumber=')[1]?.split('&')[0] : null,
            customerName: url?.includes('customerName') ? url?.split('customerName=')[1]?.split('&')[0] : null,
            serialNumber: url?.includes('serialNumber') ? url?.split('serialNumber=')[1]?.split('&')[0] : null,
            employeeName: url?.includes('employeeName') ? url?.split('employeeName=')[1]?.split('&')[0] : null,
            contractRegistrationDateFrom: url?.includes('contractRegistrationDateFrom') ? url?.split('contractRegistrationDateFrom=')[1]?.split('&')[0] : null,
            contractRegistrationDateTo: url?.includes('contractRegistrationDateTo') ? url?.split('contractRegistrationDateTo=')[1]?.split('&')[0] : null,
        };

        for ([key, value] of Object.entries(formData)) {
            if (value !== undefined && value !== null) {
                formArray.push([key, value]);
            }
        }

        const payLoad = Object.fromEntries(formArray);

        ContractService.searchContractForList(payLoad)
            .then((response) => {
                if (response?.status != 200) {
                    dispatchStore({
                        type: SET_ERROR_OBJECT,
                        val: { dictionaryObject: 'common_errors', errorCode: response?.status },
                    });
                } else {
                    const tempArray: any[] = [];
                    const contractListFromContract = response?.data?._embedded?.contracts;
                    for (let i = 0; i < contractListFromContract.length; i++) {
                        const tempData = [
                            i + 1 + getContract?.contractListPageInfo?.number * pageSize,
                            contractListFromContract[i]?.contractNumber,
                            // JSON.stringify(contractListFromContract[i]?.serialNumber),
                            contractListFromContract[i]?.serialNumber,
                            contractListFromContract[i]?.warrantyStartDate ? dateFormatterBe(new Date(contractListFromContract[i]?.warrantyStartDate)) : '',
                            contractListFromContract[i]?.employee?.name,
                            contractListFromContract[i]?.ewType?.typeName,
                            contractListFromContract[i]?.contractExpireDate ? dateFormatterBe(new Date(contractListFromContract[i]?.contractExpireDate)) : '',
                            contractListFromContract[i]?.customerName,
                            contractListFromContract[i]?.contractStatus == 'A' ? 'Active' : 'Cancelled',
                        ];
                        tempArray.push(tempData);
                    }
                    const header = [
                        '#',
                        `${t('list_contracts.contract_number')}`,
                        `${t('list_contracts.serial_number')}`,
                        `${t('list_contracts.warranty_start_date')}`,
                        `${t('list_contracts.salesman')}`,
                        `${t('list_contracts.warranty_type')}`,
                        `${t('list_contracts.expiry_date')}`,
                        `${t('list_contracts.name')}`,
                        `${t('list_contracts.status')}`,
                    ];
                    tempArray.unshift(header);
                    const wb = writeXLSX.utils.book_new();
                    const ws = writeXLSX.utils.aoa_to_sheet(tempArray);
                    ws['!cols'] = [{ width: 5 }, { width: 17 }, { width: 20 }, { width: 15 }, { width: 10 }, { width: 50 }, { width: 15 }, { width: 30 }, { width: 10 }];
                    // for (let i = 0; i < ws.length; i++) {
                    // }
                    ws['A1'].s = {
                        alignment: {
                            horizontal: 'center',
                            wrapText: true,
                        },
                        font: {
                            bold: true,
                        },
                    };
                    ws['B1'].s = {
                        alignment: {
                            horizontal: 'center',
                            wrapText: true,
                        },
                        font: {
                            bold: true,
                        },
                    };
                    ws['C1'].s = {
                        alignment: {
                            horizontal: 'center',
                            wrapText: true,
                        },
                        font: {
                            bold: true,
                        },
                    };
                    ws['D1'].s = {
                        alignment: {
                            horizontal: 'center',
                            wrapText: true,
                        },
                        font: {
                            bold: true,
                        },
                    };
                    ws['E1'].s = {
                        alignment: {
                            horizontal: 'center',
                            wrapText: true,
                        },
                        font: {
                            bold: true,
                        },
                    };
                    ws['F1'].s = {
                        alignment: {
                            horizontal: 'center',
                            wrapText: true,
                        },
                        font: {
                            bold: true,
                        },
                    };
                    ws['G1'].s = {
                        alignment: {
                            horizontal: 'center',
                            wrapText: true,
                        },
                        font: {
                            bold: true,
                        },
                    };
                    ws['H1'].s = {
                        alignment: {
                            horizontal: 'center',
                            wrapText: true,
                        },
                        font: {
                            bold: true,
                        },
                    };
                    ws['I1'].s = {
                        alignment: {
                            horizontal: 'center',
                            wrapText: true,
                        },
                        font: {
                            bold: true,
                        },
                    };
                    writeXLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
                    writeXLSX.writeFile(wb, 'contract_list.xlsx', { bookType: 'xlsx', type: 'array' });
                }
                // setNotification('Success', response?.data?.deviceId, 'success');
            })
            .catch((err) => {
                console.log('ERROR', err);
                dispatchStore({
                    type: SET_ERROR_OBJECT,
                    val: { dictionaryObject: 'common_errors', errorCode: err?.status },
                });
            });
    }

    const handleChange = (e) => {
        console.log(e.target.value);
        setPageSize(e.target.value);
        const url = getContract?.contractListLinks?.first?.href ? getContract?.contractListLinks?.first?.href : getContract?.contractListLinks?.self?.href;
        const formArray = new Array<Array<string | number>>();
        let key: string | number;
        let value: string | number;
        const formData = {
            page: url?.split('page=')[1]?.split('&')[0],
            // size: sessionStorage.getItem('pageSize') ? sessionStorage.getItem('pageSize') : 10,
            size: e.target.value,
            contractNumber: url?.includes('contractNumber') ? url?.split('contractNumber=')[1]?.split('&')[0] : null,
            customerName: url?.includes('customerName') ? url?.split('customerName=')[1]?.split('&')[0] : null,
            serialNumber: url?.includes('serialNumber') ? url?.split('serialNumber=')[1]?.split('&')[0] : null,
            employeeName: url?.includes('employeeName') ? url?.split('employeeName=')[1]?.split('&')[0] : null,
            contractRegistrationDateFrom: url?.includes('contractRegistrationDateFrom') ? url?.split('contractRegistrationDateFrom=')[1]?.split('&')[0] : null,
            contractRegistrationDateTo: url?.includes('contractRegistrationDateTo') ? url?.split('contractRegistrationDateTo=')[1]?.split('&')[0] : null,
        };

        for ([key, value] of Object.entries(formData)) {
            if (value !== undefined && value !== null) {
                formArray.push([key, value]);
            }
        }

        const payLoad = Object.fromEntries(formArray);
        dispatchStore({ type: SEARCH_CONTRACT_FOR_LIST, val: payLoad });
    };
    useEffect(() => {
        if (contractListFromContract && contractListFromContract.length > 0) {
            const tempArray: any[] = [];
            for (let i = 0; i < contractListFromContract.length; i++) {
                const tempData = [
                    i + 1 + getContract?.contractListPageInfo?.number * pageSize,
                    contractListFromContract[i]?.contractNumber,
                    // JSON.stringify(contractListFromContract[i]?.serialNumber),
                    contractListFromContract[i]?.serialNumber,
                    contractListFromContract[i]?.warrantyStartDate ? dateFormatterBe(new Date(contractListFromContract[i]?.warrantyStartDate)) : '',
                    contractListFromContract[i]?.employee?.name,
                    contractListFromContract[i]?.ewType?.typeName,
                    contractListFromContract[i]?.contractExpireDate ? dateFormatterBe(new Date(contractListFromContract[i]?.contractExpireDate)) : '',
                    contractListFromContract[i]?.customerName,
                    contractListFromContract[i]?.contractStatus == 'A' ? 'Active' : 'Cancelled',
                ];
                tempArray.push(tempData);
            }
            console.log('tempArray', tempArray);
            setContractList(tempArray);
        }
    }, [contractListFromContract]);
    useEffect(() => {
        if (insertDate) {
            editContract(actualPartnerId, actualContractNumber);
        }
    }, [insertDate]);

    useEffect(() => {
        if (getContract?.diaries && getContract?.diaries.length > 0) {
            // setDiaries(getContract?.diaries);
            setInsertDate(getContract?.diaries?.filter((diary) => diary.action == 'INS')[0].registrationDate);
        }
    }, [getContract?.diaries]);
    useEffect(() => {
        dispatchStore({ type: GET_PROJECT_PROPERTIES });
    }, []);

    return (
        <Box sx={{ position: 'relative', width: '100%' }}>
            {/* {cancelView != null ? <CancelContract contractNumber={cancelView} closeModal={setCancelView} /> : null} */}
            {preview != null ? (
                <Modal sx={{ zIndex: '0 !important' }} className="print-preview" open={true} onClose={() => console.log('close modal')}>
                    <Box sx={{ maxWidth: '1280px', position: 'relative' }}>{/* <ContractPreview contractNumber={preview} closeModal={setPreview} /> */}</Box>
                </Modal>
            ) : null}
            <Box sx={{ zIndex: '1', transition: 'all .5s ease', opacity: `${contractList !== null ? '.1' : '1'}`, position: 'absolute', width: '100%', height: '100%', padding: '5%' }}>
                <CardMedia sx={{ zIndex: '1', width: '100%', height: '100%', margin: 'auto', objectFit: 'contain' }} component="img" image="images/orange_logo.svg" />
            </Box>
            <Box sx={{ zIndex: '2', position: 'relative' }}>
                {contractListFromContract && contractListFromContract.length > 0 ? (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="right">#</TableCell>
                                    <TableCell>{t('list_contracts.contract_number')}</TableCell>
                                    <TableCell align="center">{t('list_contracts.warranty_start_date')}</TableCell>
                                    <TableCell align="center">{t('list_contracts.salesman')}</TableCell>
                                    <TableCell align="center">{t('list_contracts.warranty_type')}</TableCell>
                                    <TableCell align="center">{t('list_contracts.expiry_date')}</TableCell>
                                    <TableCell align="center">{t('list_contracts.name')}</TableCell>
                                    <TableCell align="center">{t('list_contracts.status')}</TableCell>
                                    <TableCell align="center">{t('list_contracts.functions')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {contractListFromContract.map((row, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            {index + 1 + getContract?.contractListPageInfo?.number * pageSize}
                                        </TableCell>
                                        <TableCell align="center">{row?.contractNumber}</TableCell>
                                        <TableCell align="center">{row?.warrantyStartDate ? dateFormatterBe(new Date(row?.warrantyStartDate)) : ''}</TableCell>
                                        <TableCell align="center">{row?.employee?.name}</TableCell>
                                        <TableCell align="center">{row?.ewType?.typeName}</TableCell>
                                        <TableCell align="center">{row?.contractExpireDate ? dateFormatterBe(new Date(row?.contractExpireDate)) : ''}</TableCell>
                                        <TableCell align="center">{row?.customerName}</TableCell>
                                        <TableCell align="center">{row?.contractStatus == 'A' ? 'Active' : 'Cancelled'}</TableCell>
                                        <TableCell align="center">
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Tooltip title={`${row?.warrantyCancelDate ? t('contract.view_contract') : ''}`}>
                                                    <Box
                                                        sx={{
                                                            marginRight: '.5rem',
                                                            cursor: 'pointer',
                                                            backgroundColor: '#ff7900',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignrows: 'center',
                                                            width: 'fit-content',
                                                            padding: '.5rem',
                                                        }}
                                                        onClick={() => viewContract(row?.partnerId, row?.contractNumber)}
                                                    >
                                                        <PreviewIcon sx={{ color: 'white', fontSize: '2rem' }} />
                                                    </Box>
                                                </Tooltip>
                                                <Tooltip title={`${row?.warrantyCancelDate ? t('contract.edit_contract') : ''}`}>
                                                    <Box
                                                        className={`editContract-${row.contractNumber}`}
                                                        sx={{
                                                            marginRight: '.5rem',
                                                            cursor: 'pointer',
                                                            backgroundColor: '#ff7900',
                                                            display: 'flex',
                                                            justifyContent: 'cneter',
                                                            alignrows: 'center',
                                                            width: 'fit-content',
                                                            padding: '.5rem',
                                                        }}
                                                        onClick={() => {
                                                            if (row?.warrantyCancelDate) {
                                                                setNotification('Warning', `#${row?.contractNumber} ${t('contract.contract_cancelled')}`, 'warning');
                                                                return;
                                                            }
                                                            getDiaries(row?.partnerId, row?.contractNumber);
                                                            // editContract(row?.partnerId, row?.contractNumber);
                                                        }}
                                                    >
                                                        <EditIcon sx={{ color: 'white', fontSize: '2rem' }} />
                                                    </Box>
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    ''
                )}
                {/*  */}
                {getContract?.contractListLinks?.self?.href && contractList && contractList.length > 0 ? (
                    <Grid container sx={{ marginTop: '10px' }}>
                        <Grid item xs={10}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Button
                                    disabled={!getContract?.contractListLinks?.first?.href || getContract?.contractListLinks?.self?.href == getContract?.contractListLinks?.first?.href}
                                    onClick={() => pagination(`${getContract?.contractListLinks?.first?.href}`)}
                                >
                                    <span>{`<< First`}</span>
                                </Button>
                                <Button disabled={!getContract?.contractListLinks?.prev?.href} onClick={() => pagination(`${getContract?.contractListLinks?.prev?.href}`)}>
                                    <span>{`< Prev`}</span>
                                </Button>
                                <Typography sx={{ display: 'inline' }} variant="body1">
                                    {getContract?.contractListPageInfo?.number + 1} / {getContract?.contractListPageInfo?.totalPages}
                                </Typography>
                                <Button disabled={!getContract?.contractListLinks?.next?.href} onClick={() => pagination(`${getContract?.contractListLinks?.next?.href}`)}>
                                    <span>{`Next >`}</span>
                                </Button>
                                <Button
                                    disabled={!getContract?.contractListLinks?.last?.href || getContract?.contractListLinks?.self?.href == getContract?.contractListLinks?.last?.href}
                                    onClick={() => pagination(`${getContract?.contractListLinks?.last?.href}`)}
                                >
                                    <span>{`Last >>`}</span>
                                </Button>
                                <div id="" style={{ backgroundColor: '#fff', marginLeft: '20px' }}>
                                    <FormControl fullWidth>
                                        <Select labelId="pageSizeSelector" id="pageSizeSelector" value={pageSize} label="pageSizeSelector" onChange={handleChange}>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                            <MenuItem value={40}>40</MenuItem>
                                            <MenuItem value={100}>100</MenuItem>
                                            {/* <MenuItem value={200}>200</MenuItem>
                                            <MenuItem value={500}>500</MenuItem> */}
                                        </Select>
                                    </FormControl>
                                    {/* id="tableListLength"
                                        name="tableListLength"
                                        component={renderSelectField}
                                        options={tableLengthList}
                                        type="hidden"
                                        // placeholder={t('login.language')}
                                        value="selectedLanguage"
                                        defaultValue={10}
                                        isDisabled={false} */}
                                </div>
                            </Box>
                        </Grid>
                        <Grid xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Box>
                                <Button variant="outlined" onClick={handleDownloadExcel}>
                                    <span>Download Excel</span>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    ''
                )}
            </Box>
        </Box>
    );
};

export default ContractList;
