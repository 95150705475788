import { SelectListItem } from '@src/interfaces/RootState';
import { SET_BANK_ACCOUNT } from '@src/redux/contract/actions';
import { BankAccount } from '../interfaces/BankAccount';
import store from '@src/redux/store';
import { change } from 'redux-form';
export class BankAccountModel implements BankAccount {
    bankName: string;
    bankCountry: string;
    bankPostCode: string;
    bankCity: string;
    bankStreet: string;
    bankStreetNumber: string;
    iban: string;
    bic: string;
    bankCountryItem: SelectListItem;
    constructor(bankAccount: BankAccount) {
        this.bankName = bankAccount.bankName;
        this.bankCountry = bankAccount.bankCountry;
        this.bankPostCode = bankAccount.bankPostCode;
        this.bankCity = bankAccount.bankCity;
        this.bankStreet = bankAccount.bankStreet;
        this.bankStreetNumber = bankAccount.bankStreetNumber;
        this.iban = bankAccount.iban;
        this.bic = bankAccount.bic;
        this.bankCountryItem = store.getState().unit.countryList.find((item: SelectListItem) => item.value === bankAccount.bankCountry);
    }
    public summarize() {
        return {
            bank_name: this.bankName,
            bank_country: this.bankCountry,
            bank_post_code: this.bankPostCode,
            bank_city: this.bankCity,
            bank_street: this.bankStreet,
            bank_street_number: this.bankStreetNumber,
            bank_iban: this.iban,
            bic: this.bic,
        };
    }
    public static populateForm() {
        console.log('populate bank...');
        const bankAccountModel = store.getState().contract.bankAccountModel;
        if (bankAccountModel) {
            Object.entries(bankAccountModel).forEach((element) => {
                store.dispatch(change('bankAccountForm', element[0], element[1]));
            });
        }
    }
    public static storeBankAccount(instance) {
        store.dispatch({ type: SET_BANK_ACCOUNT, val: instance });
    }
}
