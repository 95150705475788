import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
// import { SEARCH_CONTRACT, SET_CONTRACT_LIST } from '@src/redux/contract/actions';
import { SET_ERROR_OBJECT } from '@src/redux/error/actions';
import { length, required } from 'redux-form-validators';
import renderTextField from '../form/TextField';
import { SEARCH_CONTRACT, SEARCH_CONTRACT_FOR_LIST } from '@src/redux/contract/actions';
import RootState from '@src/interfaces/RootState';
import renderDatePickerField from '../form/MobileDatePicker';
import dateFormatter from '../Utility/util';
import dayjs, { Dayjs } from 'dayjs';

export type SearchForm = {
    size: string;
    contractNumber: string;
    serialNumber: string;
    customerName: string;
    employeeName: string;
    contractRegistrationDateFrom: string | Dayjs | null;
    contractRegistrationDateTo: string | Dayjs | null;
};
const SearchForm = ({ handleSubmit, pageSize }) => {
    const { t } = useTranslation('common');
    const dispatchStore = useDispatch();
    const getValues = useSelector((state: RootState) => state?.form?.searchForm?.values);

    const performSearch = () => {
        // console.log('SearchForm ~ getValues:', getValues);
        // if (
        //     (getValues?.contractNumber == undefined || getValues?.contractNumber == '') &&
        //     (getValues?.serialNumber == undefined || getValues?.serialNumber == '') &&
        //     (getValues?.customerName == undefined || getValues?.customerName == '') &&
        //     (getValues?.employeeName == undefined || getValues?.employeeName == '') &&
        //     getValues?.contractRegistrationDateFrom == undefined &&
        //     getValues?.contractRegistrationDateTo == undefined
        // ) {
        //     dispatchStore({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 400 } });
        //     return;
        // }

        const payload: SearchForm = {
            size: pageSize,
            contractNumber: getValues?.contractNumber,
            serialNumber: getValues?.serialNumber,
            customerName: getValues?.customerName,
            employeeName: getValues?.employeeName,
            contractRegistrationDateFrom: getValues?.contractRegistrationDateFrom ? dateFormatter(dayjs(getValues?.contractRegistrationDateFrom).toDate()) : null,
            contractRegistrationDateTo: getValues?.contractRegistrationDateTo ? dateFormatter(dayjs(getValues?.contractRegistrationDateTo).toDate()) : null,
        };

        dispatchStore({ type: SEARCH_CONTRACT_FOR_LIST, val: payload });
        // dispatchStore({ type: SEARCH_CONTRACT, val: payload });
    };

    return (
        <form className="search__form" onSubmit={handleSubmit(() => performSearch())}>
            <Grid container sx={{ width: '100%', margin: 'auto', padding: '1rem' }} spacing={2}>
                <Grid item xs={11}>
                    <Grid container>
                        <Grid item xs={2} sx={{ padding: '.5rem' }}>
                            <Field
                                name="contractNumber"
                                id="contractNumber"
                                component={renderTextField}
                                type="text"
                                label={t('list_contracts.contract_number')}
                                placeholder={t('list_contracts.contract_number')}
                                validate={[length({ max: 65, message: t('validations.description_length_max', { length: '65' }) })]}
                            />
                        </Grid>
                        <Grid item xs={2} sx={{ padding: '.5rem' }}>
                            <Field
                                name="serialNumber"
                                id="serialNumber"
                                component={renderTextField}
                                type="text"
                                label={t('list_contracts.serial_number')}
                                placeholder={t('list_contracts.serial_number')}
                                validate={[length({ max: 65, message: t('validations.description_length_max', { length: '65' }) })]}
                            />
                        </Grid>
                        <Grid item xs={2} sx={{ padding: '.5rem' }}>
                            <Field
                                name="customerName"
                                id="customerName"
                                component={renderTextField}
                                type="text"
                                label={t('list_contracts.name')}
                                placeholder={t('list_contracts.name')}
                                validate={[length({ max: 65, message: t('validations.description_length_max', { length: '65' }) })]}
                            />
                        </Grid>
                        <Grid item xs={2} sx={{ padding: '.5rem' }}>
                            <Field
                                name="contractRegistrationDateFrom"
                                id="contractRegistrationDateFrom"
                                component={renderDatePickerField}
                                type="text"
                                label={t('list_contracts.reg_date_from')}
                                placeholder={t('list_contracts.reg_date_from')}
                                maxDate={Date.now()}
                                defaultValue={null}
                                validate={[length({ max: 65, message: t('validations.description_length_max', { length: '65' }) })]}
                            />
                        </Grid>
                        <Grid item xs={2} sx={{ padding: '.5rem' }}>
                            <Field
                                name="contractRegistrationDateTo"
                                id="contractRegistrationDateTo"
                                component={renderDatePickerField}
                                type="text"
                                label={t('list_contracts.reg_date_to')}
                                placeholder={t('list_contracts.reg_date_to')}
                                maxDate={Date.now()}
                                defaultValue={null}
                                validate={[length({ max: 65, message: t('validations.description_length_max', { length: '65' }) })]}
                            />
                        </Grid>
                        <Grid item xs={2} sx={{ padding: '.5rem' }}>
                            <Field
                                name="employeeName"
                                id="employeeName"
                                component={renderTextField}
                                type="text"
                                label={t('list_contracts.salesman')}
                                placeholder={t('list_contracts.salesman')}
                                validate={[length({ max: 65, message: t('validations.description_length_max', { length: '65' }) })]}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={1} sx={{ pointer: 'cursor', padding: '.5rem' }}>
                    <Box
                        sx={{
                            cursor: 'pointer',
                            backgroundColor: '#ff7900',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 'fit-content',
                            height: '100%',
                            // padding: '.5rem',
                        }}
                    >
                        <Button type="submit" sx={{ height: '100%' }}>
                            <SearchIcon sx={{ color: 'white', fontSize: '2rem' }} />
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};
export default reduxForm({
    form: 'searchForm',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: false,
    touchOnBlur: true,
})(SearchForm);
