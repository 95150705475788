import { SelectListItem } from '@src/interfaces/RootState';
import { SET_ACCOUNT_HOLDER } from '@src/redux/contract/actions';
import { AccountHolder } from './../interfaces/AccountHolder';
import store from '@src/redux/store';
import { change } from 'redux-form';

export class AccountHolderModel implements AccountHolder {
    customerNameAh: string;
    countryAh: string;
    languageAh: string;
    postCodeAh: string;
    cityAh: string;
    streetAh: string;
    houseNumberAh: string;
    phoneNumberAh: number;
    emailAh: string;
    countryItem: SelectListItem;
    languageItem: SelectListItem;

    constructor(accountHolder: AccountHolder) {
        this.customerNameAh = accountHolder.customerNameAh;
        this.countryAh = accountHolder.countryAh;
        this.languageAh = accountHolder.languageAh;
        this.postCodeAh = accountHolder.postCodeAh;
        this.cityAh = accountHolder.cityAh;
        this.streetAh = accountHolder.streetAh;
        this.houseNumberAh = accountHolder.houseNumberAh;
        this.phoneNumberAh = accountHolder.phoneNumberAh;
        this.emailAh = accountHolder.emailAh;
        this.countryItem = store.getState().unit.countryList.find((item: SelectListItem) => item.value === accountHolder.countryAh);
        this.languageItem = store.getState().unit.languageList.find((item: SelectListItem) => item.value === accountHolder.languageAh);
    }
    public summarize() {
        return {
            customer_name_ah: this.customerNameAh,
            country_ah: this.countryItem.label,
            post_code_ah: this.postCodeAh,
            city_ah: this.cityAh,
            street_ah: this.streetAh,
            house_number_ah: this.houseNumberAh,
            phone_number_ah: this.phoneNumberAh,
            email_ah: this.emailAh,
        };
    }
    public static populateForm() {
        const accountHolderModel = store.getState().contract.accountHolderModel;
        if (accountHolderModel) {
            Object.entries(accountHolderModel).forEach((element) => {
                store.dispatch(change('accountHolderForm', element[0], element[1]));
            });
        }
    }
    public static storeAccountHolder(instance) {
        store.dispatch({ type: SET_ACCOUNT_HOLDER, val: instance });
    }
}
